import { useState, useEffect } from "react";
import { UserType } from "types";

let chmlnInitialised = false;
const chameleonToken = window.elearningRuntimeConfig.CHAMELEON_API_KEY;
const CHAMELEON_BLACKLIST =
  (window.elearningRuntimeConfig.CHAMELEON_BLACKLIST as string)?.split(",").map((i) => i.trim()) ||
  [];

const useChameleon = (user?: UserType | undefined) => {
  const [chmln, setChmln] = useState<null | any>(null);
  const [isChameleonActive, setIsChameleonActive] = useState<boolean>(
    window.elearningRuntimeConfig.CHAMELEON_ACTIVE === "true"
  );

  useEffect(() => {
    if (!user || chmlnInitialised) {
      return;
    }

    if (user?.customer?.uuid && CHAMELEON_BLACKLIST.includes(user?.customer?.uuid)) {
      setIsChameleonActive(false);
      return;
    }

    if (isChameleonActive) {
      import("@chamaeleonidae/chmln")
        .then((module) => {
          if (!chmlnInitialised) {
            setChmln(module);
            module.init(chameleonToken);
            chmlnInitialised = true;

            module.identify(user?.address_uuid ?? user?.uuid, {
              language: user?.language ?? window.navigator.language,
              role: user?.email?.endsWith("sosafe.de") ? "employee" : "customer",
              company: {
                uid: user?.customer?.uuid,
              },
            });
          }
        })
        .catch((error) => {
          console.error("Error loading Chameleon module:", error);
        });
    }
  }, [isChameleonActive, user, chameleonToken]);

  // Define methods that conditionally call the Chameleon functions
  const identify = (...args: any[]) => {
    if (chmln) {
      chmln.identify(...args);
    }
  };

  const track = (...args: any[]) => {
    if (chmln) {
      chmln.track(...args);
    }
  };

  const clear = (...args: any[]) => {
    if (chmln) {
      chmln.clear(...args);
    }
  };

  return {
    isSupportedLanguage: (language: string) => ["en", "de"].includes(language),
    identify,
    track,
    clear,
  };
};

export default useChameleon;
