import React, { createContext } from 'react';
import { Modal } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { useDisclosure } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react/hooks';

type OnFireProps = {
  html: React.ReactElement,
  onClose: () => Promise<void>
  size?: string
  xOffset?: number | string
  yOffset?: number | string
};

type ModalContextType = {
  fire: (setup: OnFireProps) => void;
  close: () => Promise<void>;
}

const ModalContext = createContext<ModalContextType>({
  fire: () => {
    console.warn("fire not implemented")

  },
  close: async () => { console.warn("close not implemented") }
});

interface Props {
  children: React.ReactElement
}

export const ModalContextProvider = ({ children }: Props) => {
  const [isOpen, action] = useDisclosure();
  const [state, setState] = React.useState<OnFireProps | null>(null);
  const { onClose, html, ...defaultModalProps } = state || {};

  const fire = (setup: OnFireProps) => {
    setState(setup);
    action.open()
  }

  const close = async () => {
    if (state) {
      await state.onClose();
    }
    action.close();
  }

  return (
    <ModalContext.Provider value={{ fire, close }}>
      {children}
      <Modal opened={isOpen} onClose={close} {...defaultModalProps} centered>
        {html}
      </Modal>
    </ModalContext.Provider>
  );
}

export const useModalContext = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used within a ModalContextProvider');
  }
  return context;
}
