import React, { useMemo } from "react";
import { ClockIcon } from "@sosafe-platform-engineering/fe-lib-ui-react";
import {
  Card,
  Badge,
  Progress,
  Text,
  Button,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { getDayAndMonthYearString } from "shared/utilities/get-day-month-year-string";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getModuleKey } from "elearning/helpers";
import { ModuleCardType } from "../module-card.types";

import style from "./path-module-card.module.css";
import isOverDue from "../../shared/is-module-overdue";

export const PathModuleCard = ({
  ActionButton,
  StatusTag,
  module,
  userLanguage,
  highLight,
}: ModuleCardType) => {
  const { t } = useTranslation("flamingo");
  const history = useHistory();
  const {
    thumbnailUrl,
    name,
    mandatory,
    progress,
    status,
    finished_by,
    duration,
    awarenessAssessmentIds
  } = module;
  const moduleFinishBy = getDayAndMonthYearString(finished_by, userLanguage);
  const isModuleOverdue = isOverDue(finished_by);
  const moduleComplete = status === MODULE_STATUS.RESTART;
  const hideProgressBar = status === MODULE_STATUS.CONTINUE && progress === 100;
  const shouldShowDueDateTag =
    thumbnailUrl && mandatory && finished_by && !moduleComplete;

  const mobileView = useMemo(() => window.innerWidth <= 480, [window]);
  const hasAwarenessAssessment = status == MODULE_STATUS.AWARENESS_ASSESSMENT;

  const onTakeAwarenessAssessment = () => {
    const randomId = awarenessAssessmentIds[Math.floor(Math.random() * awarenessAssessmentIds.length)];
    history.push("/awareness-assessment", {
      id: randomId,
      module,
    });
  }

  const onRestartLesson = () => {
    history.push(`/elearning/${module.category}/${getModuleKey(module)}`);
  }

  const progressDisplay = moduleComplete ? 100 : progress;

  return (
    <Card
      withBorder
      radius="lg"
      p={0}
      className={`${style.card} ${highLight ? style.highLight : style.nonHighlight
        }`}
    >
      {thumbnailUrl && (
        <div className={style.headerImage}>
          <img src={thumbnailUrl} alt={module.name} />
        </div>
      )}
      <div className={style.body}>
        <div className={style.details}>
          <div className={style.tags}>

            {(!moduleComplete || hasAwarenessAssessment) && (
              <Badge
                variant={"warning"}
                size={mobileView ? "xs" : "md"}
              >
                {t("mandatory")}
              </Badge>
            )}

            <StatusTag tagSize={mobileView ? "xs" : "md"} />

            {shouldShowDueDateTag && moduleFinishBy && (
              <Badge
                variant={isModuleOverdue ? "danger" : "neutral"}
                size={mobileView ? "xs" : "md"}
              >{`${t("dueOn")} ${moduleFinishBy}`}</Badge>
            )}
          </div>
          <div className={style.duration}>
            <ClockIcon
              aria-label={t("averageDuration")}
              className={style.icon}
            />
            <small className={style.durationText}>
              ~{duration} {t("min")}
            </small>
          </div>
        </div>
        <div className={style.content}>
          <div className={style.label}>
            <h3>{name}</h3>
          </div>

          {!hasAwarenessAssessment && (
            <div
              className={
                hideProgressBar ? style.hideProgressBar : style.progressBarMenu
              }
            >
              {!hideProgressBar && (
                <>
                  <Progress
                    role="progressbar"
                    aria-valuenow={progressDisplay}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    value={Math.floor(progressDisplay)}
                    className={style.progressBar}
                    aria-labelledby="progress-label"
                  />
                  <Text id="progress-label" size="xs" c="#000">
                    {progressDisplay}%
                  </Text>
                </>
              )}
              <ActionButton type="learning-path" />
            </div>
          )}

          {hasAwarenessAssessment && (
            <div className={style.awarenessAssessmentContainer}>
              <Button
                size="xs"
                variant={highLight ? "primary" : "secondary"}
                onClick={onTakeAwarenessAssessment} >
                {t("awarenessAssessment_pathModuleCard_button_takeAssessment")}
              </ Button>
              <Button
                size="xs"
                variant={highLight ? "secondary" : "naked"}
                onClick={onRestartLesson} >
                {t("awarenessAssessment_pathModuleCard_button_restartLesson")}
              </ Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
