import {
  ChevronDownIcon,
  DropdownMenuSeparator,
  MenuIcon,
  clsx,
} from "@sosafe-platform-engineering/fe-lib-ui-react";
import { Avatar, Text, Menu, MenuItem } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { IconUser, IconSchool, IconQuestionMark, IconLifebuoy, IconLogout, IconShield } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons"
import { ACHIEVEMENT_PAGE_OPENED_FROM } from "elearning/helpers";
import React, { useCallback, useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useMetaLinks from "shared/hooks/use-meta-links";
import { useHistory, useLocation } from "react-router";
import Logo from "shared/components/header/logo-header";
import { getDisplayName } from "shared/helpers";
import getShortName from "shared/helpers/get/get-short-name";
import GlobalSettingsContext from "shared/modules/global-settings/state/global-settings-context.provider";
import usePlatformSettings from "shared/hooks/use-platform-settings";
import { ICON_COLOR } from "shared/utilities/constants";
import useUser from "shared/hooks/use-user";
import useOutsideAlerter from "shared/hooks/use-outside-click";
import styles from "./header.module.css";

/**
 *
 * @category flamingo
 * @exports Header
 * @component
 */

type HeaderType = {
  handleSignOutClick: () => void;
  minimizedView: boolean;
};

type HeaderButtonType = {
  link: string;
  translation: string;
  redirect?: string;
  page: string;
};

type HeaderMenuButtonType = {
  page: string;
  translation: string;
  Icon?: any;
  iconColor?: ICON_COLOR;
  areaLabel: string;
};

export default function Header(props: HeaderType) {
  const { user } = useUser({ enabled: false });
  const { t } = useTranslation("flamingo");
  const { getSupportLink, support } = useMetaLinks();
  const displayName = getDisplayName(user);
  const circleName = getShortName(user);
  const fallbackName = t("learner");
  const { handleSignOutClick, minimizedView } = props;
  const { globalSettings } = useContext(GlobalSettingsContext);
  const { settings: platformSettings } = usePlatformSettings();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const history = useHistory();

  const location = useLocation();
  const path = location.pathname;

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsMenuOpen);

  const isItemBold = (page: string, redirect?: string) => {
    if (path.includes(page) || path === redirect) {
      return styles.leftMenuItemActive;
    }
    return styles.leftMenuItem;
  };

  const onNavClickMap = new Map([
    ["training", () => history.push("/")],
    [
      "achievements",
      () =>
        history.push({
          pathname: "/achievements",
          state: {
            openedFrom: ACHIEVEMENT_PAGE_OPENED_FROM.START_PAGE.HEADER_MENU,
          },
        }),
    ],
    ["account", () => history.push("/account")],
    ["faq", () => history.push("/faq")],
    ["support", () => window.open(getSupportLink(), "_blank")],
    ["downloads", () => history.push("/downloads")],
    ["logout", () => handleSignOutClick()],
  ]);

  const onClickHandle = (type: string) => {
    onNavClickMap.get(type)?.();
  };

  const HeaderButton = useCallback(
    ({ link, translation, redirect, page }: HeaderButtonType) => (
      <Link
        data-testid={page}
        to={link}
        aria-label={translation}
        className={clsx(styles.dropBtn, isItemBold(page, redirect))}
      >
        {translation}
      </Link>
    ),
    [path]
  );

  const HeaderMenuDropdown = useCallback(
    ({
      page,
      translation,
      Icon,
      iconColor
    }: HeaderMenuButtonType) => (
      <MenuItem
        leftSection={<Icon style={iconColor ? { color: iconColor } : undefined} />}
        area-label={circleName || fallbackName.charAt(0)}
        onClick={() => onClickHandle(page)}
        data-testid={`dropdown-${page}`}
      >
        <Text size="md" fw={500}>
          {translation}
        </Text>
      </MenuItem>
    ),
    [path]
  );

  return (
    <header className={styles.header}>
      <div className={styles.wrapper}>
        <div className={styles.leftMenu}>
          <div className={styles.logo}>
            <Logo theme={globalSettings} />
          </div>
          {!minimizedView && (
            <nav
              className={styles.leftMenuNav}
              aria-label={t("menu-label")}
            >
              <HeaderButton
                link="/"
                translation={t("training")}
                redirect="/"
                page="training"
              />
              {(platformSettings?.achievements ||
                platformSettings?.material_area) && (
                  <HeaderButton
                    link="/achievements"
                    translation={t("achievements")}
                    page="achievements"
                  />
                )}
              {platformSettings?.material_area && (
                <HeaderButton
                  link="/downloads"
                  translation={t("downloads")}
                  page="downloads"
                />
              )}
            </nav>
          )}
        </div>
        <div className={styles.rightMenu} ref={wrapperRef}>
          <div className={styles.rightAccount}>
            <Menu
              opened={isMenuOpen}
              position="bottom"
              loop={true}
              menuItemTabIndex={0}
              trigger="click"
              aria-label={`${t("openAccountDropdown")}: ${circleName || fallbackName.charAt(0)}`}
              withinPortal={true}
            >
              <Menu.Target>
                <button
                  aria-label={`${t("openAccountDropdown")}: ${displayName || fallbackName}`}
                  className={styles.dropMenuBtn}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  aria-expanded={isMenuOpen}
                  data-testid="accountButton"
                >
                  <Avatar
                    alt={t("usersAvatar")}
                    radius="xl"
                    aria-label={circleName || fallbackName.charAt(0)}
                  >
                    {circleName || fallbackName.charAt(0)}
                  </Avatar>
                  <Text c="#000" className={styles.name}>{displayName || fallbackName}</Text>
                  <ChevronDownIcon className={styles.chevIcon} />
                </button>
              </Menu.Target>

              <Menu.Dropdown className={styles.dropdownMenu}>
                <MenuItem
                  leftSection={<Avatar
                                alt={circleName || fallbackName.charAt(0)}
                                radius="xl"
                              >
                                {circleName || fallbackName.charAt(0)}
                              </Avatar>}
                  area-label={circleName || fallbackName.charAt(0)}
                  aria-labelledby="user-info-name"
                >
                    <div style={{ flex: 1 }}>
                      <Text id="user-info-name" size="md" fw={500}>
                        {displayName || fallbackName}
                      </Text>
                      <Text c="dimmed" size="xs">
                        {user?.email || t("no_email")}
                      </Text>
                    </div>
                </MenuItem>

                <DropdownMenuSeparator />
                <div className={styles.mobileNav}>
                  {!minimizedView && (
                    <div>
                      <HeaderMenuDropdown
                        page="training"
                        translation={t("training")}
                        Icon={IconSchool}
                        areaLabel={t("training")}
                      />
                      {(platformSettings?.achievements ||
                        platformSettings?.material_area) && (
                          <HeaderMenuDropdown
                            page="achievements"
                            translation={t("achievements")}
                            Icon={IconShield}
                            areaLabel={t("achievements")}
                          />
                        )}
                    </div>
                  )}
                </div>
                <HeaderMenuDropdown
                  page="account"
                  translation={t("personal_info")}
                  Icon={IconUser}
                  areaLabel={t("personal_info")}
                />
                <HeaderMenuDropdown
                  page="faq"
                  translation={t("fag")}
                  Icon={IconQuestionMark}
                  areaLabel={t("fag")}
                />
                {support && (
                  <HeaderMenuDropdown
                    page="support"
                    translation={t("support")}
                    Icon={IconLifebuoy}
                    areaLabel={t("support")}
                  />
                )}
                <DropdownMenuSeparator aria-hidden="true"/>
                <HeaderMenuDropdown
                  page="logout"
                  translation={t("logout")}
                  Icon={IconLogout}
                  iconColor={ICON_COLOR.DANGER}
                  areaLabel={t("logout")}
                />
              </Menu.Dropdown>
            </Menu>
          </div>
          <div className={styles.mobileNav}>
            <MenuIcon
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={styles.mobileNav}
              size="large"
            />
          </div>
        </div>
      </div>
    </header>
  );
}
