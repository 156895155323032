import React from "react";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import useInitTranslations from "lang/use-init-translations.hook";
import Loading from "shared/components/loading";
import Helmet from "shared/components/helmet";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import { useQuery } from "@tanstack/react-query";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { AwarenessAssessmentSurvey } from "./components/awareness-assessment-survey";

import style from "./awareness-assessment.page.module.css";
import translations from "./lang";

export const AwarenesssAssessmentPage = () => {
  const history = useHistory();
  const location = useLocation<{ id: number, module: PersonalizedModule }>();
  const { translationsReady } = useInitTranslations(
    translations,
    "awareness-assessment"
  );

  const { endPoints } = useSoSafeConnect();
  const { t } = useTranslation(["awareness-assessment", "helmetTranslations"]);

  const { data, isLoading: isLoadingSurvey } = useQuery(
    [queryKey.SURVEY_HUB, location.state.id],
    () => endPoints.surveyHubApi.fetch.getSurveyDataById(location.state.id),
    {
      enabled: !!location.state.id,
    });

  const onExit = () => {
    history.push("/");
  };

  if (!location.state.module) {
    history.push("/");
  }

  console.log({ translationsReady, isLoadingSurvey, data });
  if (!translationsReady || isLoadingSurvey || !data.surveyData) {
    return <Loading />;
  }

  return (
    <div className={style.container}>
      <Helmet title={t("helmetTranslations:awarenessAssessment")} />
      <div className={style.page}>
        <Button
          variant="outline"
          className={style.exitButton}
          aria-label="Exit Assessment"
          onClick={onExit}
        >
          {t("awareness-assessment:exitAssessment")}
        </Button>
        <AwarenessAssessmentSurvey
          surveyId={location.state.id}
          surveyData={data.surveyData}
          module={location.state.module}
        />
      </div>
    </div>
  );
};
