import React, { useEffect } from "react";
import ContentPage from "flamingo-e-learning-platform/content/content.page";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";
import useUser from "shared/hooks/use-user";
import ErrorPage from "shared/pages/error/error.page";
import { useLocation } from "react-router-dom";
import showHeader from "shared/helpers/personalizedLearning/show-header";
import { isUserAllowedOnPL } from "shared/hooks/use-is-user-allowed-pl";
import useAuthentication from "authentication/hooks/use-authentication";
import { useCategoriesRequest } from "elearning/hooks/use-categories-request";
import { useSurveyResult } from "survey-hub/hooks/use-survey-result";
import { FEATURE_TYPE, SURVEY_TYPE } from "survey-hub/constants";
import { CalculatingLearningPathPage } from "flamingo-e-learning-platform/calculating-learning-path";
import AccountPage from "account/pages/account-page";
import FlamingoHeader from "../../../flamingo-e-learning-platform/header/header";

export const name = "CalculatingPathRouteController";

export const routes = [
  {
    path: "/",
    render: () => <CalculatingLearningPathPage />,
  },
  {
    path: "/account",
    render: () => <AccountPage />,
  },
  {
    path: "/503",
    render: () => <ErrorPage errorCode={503} />,
  },
];

export const defaultRoute = "/";

export const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { signOut } = useAuthentication();
  const { pathname } = useLocation();
  const enableHeader = showHeader(pathname);

  useEffect(() => {
    import("../../../theme/flamingo");
  }, []);

  return (
    <React.Fragment>
      {enableHeader && (
        <FlamingoHeader handleSignOutClick={signOut} minimizedView={true} />
      )}
      <ContentPage>{children}</ContentPage>
    </React.Fragment>
  );
};

export const UseIsPathAllowed = () => {
  const { apiKey } = useSoSafeConnect();
  const { user, loading } = useUser({});
  const { isAllowed, isLoading: isLoadingUserAllowed } = isUserAllowedOnPL();

  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;
  const loadingAuthorization = apiKey ? loading : false;

  const { data: moduleData, isLoading: isLoadingModules } = useCategoriesRequest();

  const { isFetchingSurveysAnswers, answeredSurveys } = useSurveyResult({
    userId: user?.id || 0,
    surveyTypeId: SURVEY_TYPE.CULTURE_AND_CONTEXT,
    surveyFeatureId: FEATURE_TYPE.PERSONALIZED_LEARNING,
    enabled: !!apiKey && !!user?.id
  });

  const isCalculatingLearningPath = answeredSurveys?.length && moduleData?.data.result.length === 0;

  return {
    allowed: !!apiKey && isPersonalizedLearning && isAllowed && isCalculatingLearningPath,
    loading: loadingAuthorization && isLoadingUserAllowed && isLoadingModules && isFetchingSurveysAnswers,
  };
};
